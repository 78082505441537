import { Routes } from '@angular/router';
import { HomeComponent } from './Content/Main/home/home.component';
import { AboutComponent } from './Content/Main/about/about.component';
import { ContactComponent } from './Content/Main/contact/contact.component';
import { FinancialComponent } from './Content/Main/financial/financial.component';
import { OrthodonticsComponent } from './Content/Orthodontics/orthodontics/orthodontics.component';
import { BracesComponent } from './Content/Orthodontics/braces/braces.component';
import { InvisalignComponent } from './Content/Orthodontics/invisalign/invisalign.component';
import { TeeninvisalignComponent } from './Content/Orthodontics/teeninvisalign/teeninvisalign.component';
import { ClearbracesComponent } from './Content/Orthodontics/clearbraces/clearbraces.component';
import { PrivacypolicyComponent } from './Content/Main/privacypolicy/privacypolicy.component';
import { DentistComponent } from './Content/Dentist/dentist/dentist.component';
import { ImplantsComponent } from './Content/Dentist/implants/implants.component';
import { ExtractionsComponent } from './Content/Dentist/extractions/extractions.component';
import { TeethCleaningComponent } from './Content/Dentist/teeth-cleaning/teeth-cleaning.component';
import { TeethWhiteningComponent } from './Content/Dentist/teeth-whitening/teeth-whitening.component';
import { InvisalignlpComponent } from './Content/LandingPages/invisalignlp/invisalignlp.component';
import { OrtholpComponent } from './Content/LandingPages/ortholp/ortholp.component';
import { WisdomteethComponent } from './Content/Dentist/wisdomteeth/wisdomteeth.component';
import { VeneersComponent } from './Content/Dentist/veneers/veneers.component';
import { AlignersComponent } from './Content/Orthodontics/aligners/aligners.component';
import { CrownsComponent } from './Content/Dentist/crowns/crowns.component';
import { OrthoPricingComponent } from './Content/Orthodontics/ortho-pricing/ortho-pricing.component';
import { RootcanalsComponent } from './Content/Dentist/rootcanals/rootcanals.component';
import { DentalExamComponent } from './Content/Dentist/dental-exam/dental-exam.component';
import { FillingsComponent } from './Content/Dentist/fillings/fillings.component';
import { BondingComponent } from './Content/Dentist/bonding/bonding.component';
import { Ortholp2Component } from './Content/LandingPages/ortholp2/ortholp2.component';
import { Invisalign2Component } from './Content/LandingPages/invisalign2/invisalign2.component';
import { ThankyouComponent } from './Content/Main/thankyou/thankyou.component';
import { Spanishad1Component } from './Content/LandingPages/spanishad1/spanishad1.component';
import { Ortholp3Component } from './Content/LandingPages/ortholp3/ortholp3.component';
import { Ortholp4Component } from './Content/LandingPages/ortholp4/ortholp4.component';
import { BacktoschooladComponent } from './Content/LandingPages/backtoschoolad/backtoschoolad.component';

export const routes: Routes = [

  {path: '', component: HomeComponent, title: 'Home'},
  {path: 'about', component: AboutComponent, title: 'About'},
  {path: 'contact', component: ContactComponent, title: 'Contact'},
  {path: 'insurance', component: FinancialComponent, title: 'Insurance'},
  {path: 'privacypolicy', component: PrivacypolicyComponent, title: 'PrivacyPolicy'},


  //orthodontics
  {path: 'orthodontics', component: OrthodonticsComponent, title: 'Orthodontics'},
  {path: 'braces', component: BracesComponent, title: 'Braces'},
  {path: 'invisalign', component: InvisalignComponent, title: 'Invisalign'},
  {path: 'teeninvisalign', component: TeeninvisalignComponent, title: 'Teen Invisalign'},
  {path: 'clearbraces', component: ClearbracesComponent, title: 'Clear Braces'},
  {path: 'aligners', component: AlignersComponent, title: 'Clear Aligners'},
  {path: 'orthodonticprice', component: OrthoPricingComponent, title: 'Orthodontic Pricing'},



  // dental
  {path: 'dental', component: DentistComponent, title: 'Dentist'},
  {path: 'exams', component: DentalExamComponent, title: 'Dental Exams'},
  {path: 'bonding', component: BondingComponent, title: 'Dental Bonding'},

  {path: 'implants', component: ImplantsComponent, title: 'Implants'},
  {path: 'wisdomteeth', component: WisdomteethComponent, title: 'Wisdom Teeth Extractions'},
  {path: 'extractions', component: ExtractionsComponent, title: 'extractions'},
  {path: 'teeth-cleaning', component: TeethCleaningComponent, title: 'Teeth Cleaning'},
  {path: 'teeth-whitening', component: TeethWhiteningComponent, title: 'Teeth Whitening'},
  {path: 'veneers', component: VeneersComponent, title: 'Dental Veneers'},
  {path: 'crowns', component: CrownsComponent, title: 'Crowns'},
  {path: 'rootcanals', component: RootcanalsComponent, title: 'Root Canals'},
  {path: 'fillings', component: FillingsComponent, title: 'Fillings'},



// ads
  {path: 'invisalignad', component: InvisalignlpComponent, title: 'InvisalignAligners'},
  {path: 'orthobraces', component: OrtholpComponent, title: 'Orthodonticslp'},
  {path: 'orthobraces2', component: Ortholp2Component, title: 'Orthodonticslp2'},
  {path: 'invisalignad2', component: Invisalign2Component, title: 'InvisalignAligners'},
  {path: 'brackets', component: Spanishad1Component, title: 'Brackets'},
  {path: 'orthobraces3', component: Ortholp3Component, title: 'Brackets'},
  {path: 'orthobraces4', component: Ortholp4Component, title: 'Brackets'},
  {path: 'backtoschool', component: BacktoschooladComponent, title: 'Back to School'},



  // thank you
  {path: 'thankyou', component: ThankyouComponent, title: 'Thank You'},
















];
