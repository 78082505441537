import { Component } from '@angular/core';

@Component({
  selector: 'app-backtoschoolad',
  standalone: true,
  imports: [],
  templateUrl: './backtoschoolad.component.html',
  styleUrl: './backtoschoolad.component.scss'
})
export class BacktoschooladComponent {

}
